import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';

import '../scss/Home.scss';

const Home = () => {
  const [mediaYomo, setMediaYomo] = useState({
    logo: '',
    video: ''
  });
  let navigate = useNavigate();

  const getDataScreen = () => {
    const conexionDB = window.indexedDB.open('screenBD', 1);

    conexionDB.onsuccess = function (event) {
      let db = event.target.result;

      db.transaction('Configuration').objectStore('Configuration').get(2).onsuccess = function (event) {
        let { logo, video } = event.target.result;
        setMediaYomo({ logo, video });
      };
    };
  };

  useEffect(() => {
    getDataScreen();
  }, []);

  return (
    <>
      <section
        className='home__wrapper'
        onClick={() => {
          navigate('/categories');
        }}
      >
        <header className='home__header'>
          <img src={`data:image/png;base64,${mediaYomo.logo}`} alt='Logo Yomo' />
        </header>
        {mediaYomo.video !== '' && (
          <video autoPlay loop muted className='home__video'>
            <source src={mediaYomo.video} type='video/mp4' />
          </video>
        )}
      </section>
      <section className='loading-update d-none'>
        <Loading text='Actualizando datos, por favor espere...' />
      </section>
    </>
  );
};

export default Home;
