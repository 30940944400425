import React from 'react';
// import ApiScreen from '../services/screens'
// import { useNavigate } from 'react-router-dom';
// import screenLocal from '../services/screenLocal';
import logoYomo from '../images/LogoYomo.png';

import '../scss/Register.scss';

const Register = ({ code }) => {
  // let navigate = useNavigate();
  // const [codeApp, setCodeApp] = useState(0);
  // useEffect(() => {
  //   function initDataBase() {
  //     const conexionDB = window.indexedDB.open('screenBD', 1);

  //     conexionDB.onsuccess = function (event) {
  //       let db = event.target.result;
  //       debugger;

  //       const tableConfiguration = db.transaction('Configuration').objectStore('Configuration');
  //       const request = tableConfiguration.get(1);
  //       if (request.result) {
  //         request.onsuccess = function (event) {
  //           let { code } = event.target.result;
  //           setCodeApp(code);
  //         };
  //       }
  //     };
  //   }
  //   initDataBase();
  // }, []);

  return (
    <section className='register__wrapper'>
      <header className='register__loader'>
        <img className='logo-loading' src={logoYomo} alt='Logo Yomo' draggable={false} />
        <p>Modo configuración</p>
      </header>
      <main className='register__content'>
        <h1>{code}</h1>
        <span>*Coloque este código en su sistema</span>
      </main>
    </section>
  );
};

export default Register;
