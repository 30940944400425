import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './layout/Layout';
import { Animated } from 'react-animated-css';
// import ApiScreen from '../services/screens';

import '../scss/Categories.scss';

const Categories = () => {
  const [loading, setLoading] = useState(true);
  const [listCategories, setListCategories] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    const getCategories = async () => {
      setLoading(true);

      const conexionDB = window.indexedDB.open('screenBD', 1);

      conexionDB.onsuccess = function (event) {
        let db = event.target.result;

        const table = db.transaction('Categories').objectStore('Categories');

        table.getAll().onsuccess = function (e) {
          const allCategories = e.target.result;
          const parentsCategories = allCategories.filter(x => x.parent_id.length === 0);
          // const parentsCategories = allCategories.filter(x => x.parent_id === false);
          setListCategories(parentsCategories);
        };
      };

      setLoading(false);
    };

    getCategories();
  }, []);

  return (
    <Layout loading={loading} section='Categorías'>
      <div className='items__wrapper--categorie'>
        {listCategories.map(categorie => {
          return (
            <div
              className={`col-${categorie.col} item__link`}
              key={categorie.id}
              onClick={() => {
                navigate(categorie.child_id.length > 0 ? `/sub-categories/${categorie.id}` : `/products/${categorie.id}`);
              }}
            >
              <Animated animationIn='zoomIn' animationOut='fadeOut' isVisible={true} animationInDuration={500}>
                <img className='items__photo' src={`data:${categorie.image_mime_type};base64,${categorie.image_1920}`} alt={categorie.name} draggable={false} />
              </Animated>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default Categories;
