import React from 'react';
import emptyState from '../images/empty-state.png';

import '../scss/EmptyState.scss';

const EmptyState = () => {
  return (
    <section className='empty__state'>
      <img src={emptyState} alt='Estado vacio' />
      <span>No hay productos disponibles</span>
    </section>
  );
};

export default EmptyState;
