import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from './layout/Layout';
import { Animated } from 'react-animated-css';
import Modal from './Modal';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import '../scss/DetailProduct.scss';

const DetailProduct = () => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [suggested, setSuggested] = useState([]);
  const [toppings, setToppings] = useState([]);
  const [showPrice, setShowPrice] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [optionsModal, setOptionsModal] = useState({
    data: [],
    type: ''
  });
  const [section, setSection] = useState('');
  const { productId } = useParams();
  let navigate = useNavigate();

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalOpen = event => {
    setOptionsModal(event);
    setShowModal(true);
  };

  const properties = {
    duration: 3000,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    indicators: false
  };

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);

      const conexionDB = window.indexedDB.open('screenBD', 1);

      conexionDB.onsuccess = function (event) {
        let db = event.target.result;

        const table = db.transaction('Products').objectStore('Products');

        table.getAll().onsuccess = function (e) {
          const allProducts = e.target.result;

          const productFilter = allProducts.filter(x => x.id === parseInt(productId));
          setProduct(productFilter[0]);

          let productsSuggested = [];
          productFilter[0].suggested_product_ids.forEach(id => {
            const temp = allProducts.filter(x => x.id === parseInt(id));
            temp.length > 0 && productsSuggested.push(temp[0]);
          });
          setSuggested(productsSuggested);

          productFilter[0].attributes.values.length > 0 && setToppings(productFilter[0].attributes.values);

          const tableCategorie = db.transaction('Categories').objectStore('Categories');
          tableCategorie.get(productFilter[0].category_ids[0]).onsuccess = function (event) {
            const categorie = event.target.result;
            setSection(categorie.name);
          };

          db.transaction('Configuration').objectStore('Configuration').get(2).onsuccess = function (event) {
            let { show_price } = event.target.result;
            setShowPrice(show_price);
          };

          setLoading(false);
        };
      };
    };

    getProducts();
  }, [productId]);

  return (
    <Layout loading={loading} section={section}>
      {!loading > 0 && (
        <>
          <div className='content__product'>
            <div className='product__photo'>
              <Animated animationIn='zoomIn' animationOut='fadeOut' isVisible={true} animationInDuration={500}>
                <img
                  className='items__photo'
                  src={`data:${product.images[0].image_mime_type};base64,${product.images[0].image_1920}`}
                  alt={product.name}
                  draggable={false}
                />
              </Animated>
            </div>
            <div className='product__detail'>
              <Animated animationIn='zoomIn' animationOut='fadeOut' isVisible={true} animationInDuration={500}>
                <>
                  <h2 className='product__title'>{product.name}</h2>
                  <div dangerouslySetInnerHTML={{ __html: product.description }} />
                  {showPrice && parseInt(product.price) > 0 && <h3 className='product__price'>{`$${product.price}`}</h3>}
                </>
              </Animated>
            </div>

            {toppings.length > 0 && (
              <div className='content__topping'>
                <Animated animationIn='zoomIn' animationOut='fadeOut' isVisible={true} animationInDuration={500}>
                  <>
                    {product.attributes.key !== '' && <h2 className='title'>{product.attributes.key}</h2>}

                    {toppings.length > 3 ? (
                      <Slide {...properties}>
                        {toppings.map((topping, idx) => {
                          return (
                            <img
                              className='items__photo'
                              src={`data:${topping.image_mime_type_128};base64,${topping.image_128}`}
                              alt={topping.name}
                              onClick={() => {
                                handleModalOpen({
                                  data: toppings,
                                  type: 'topping'
                                });
                              }}
                              key={`topping-${idx}`}
                              draggable={false}
                            />
                          );
                        })}
                      </Slide>
                    ) : (
                      <div className='content__galery'>
                        {toppings.map((topping, idx) => {
                          return (
                            <img
                              className='items__photo'
                              src={`data:${topping.image_mime_type_128};base64,${topping.image_128}`}
                              alt={topping.name}
                              onClick={() =>
                                handleModalOpen({
                                  data: toppings,
                                  type: 'topping',
                                  index: idx
                                })
                              }
                              key={`topping-${idx}`}
                              draggable={false}
                            />
                          );
                        })}
                      </div>
                    )}
                  </>
                </Animated>
              </div>
            )}

            {suggested.length > 0 && (
              <div className='content__suggested'>
                <Animated animationIn='zoomIn' animationOut='fadeOut' isVisible={true} animationInDuration={500}>
                  <>
                    <h2 className='title'>Productos relacionados</h2>

                    {suggested.length > 3 ? (
                      <Slide {...properties}>
                        {suggested.map((product, idx) => {
                          return (
                            <div className='items__content--photo' key={`suggested-${idx}`}>
                              <img
                                className='items__photo--product'
                                src={`data:${product.images[0].image_mime_type};base64,${product.images[0].image_1920}`}
                                alt={product.name}
                                onClick={() => {
                                  navigate(`/detail-product/${product.id}`);
                                }}
                                draggable={false}
                              />
                              <div className='items__name'>{product.name}</div>
                            </div>
                          );
                        })}
                      </Slide>
                    ) : (
                      <div className='content__galery'>
                        {suggested.map((product, idx) => {
                          return (
                            <div
                              className='content__product--suggested'
                              onClick={() => {
                                navigate(`/detail-product/${product.id}`);
                              }}
                              key={`suggested-${idx}`}
                            >
                              <img
                                className='items__photo--product'
                                src={`data:${product.images[0].image_mime_type};base64,${product.images[0].image_1920}`}
                                alt={product.name}
                                draggable={false}
                              />
                              <div className='items__name'>{product.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                </Animated>
              </div>
            )}
          </div>
          {showModal && <Modal options={optionsModal} handleClose={() => handleModalClose()} />}
        </>
      )}
    </Layout>
  );
};

export default DetailProduct;
