import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from './layout/Layout';
import { Animated } from 'react-animated-css';
import arrowMoreProducts from '../images/go-back-arrow.png';
// import EmptyState from './EmptyState';

const Products = () => {
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState('');
  const [listProducts, setListProducts] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const { categorieId } = useParams();
  let navigate = useNavigate();
  const wrapperItems = useRef(null);

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      const conexionDB = window.indexedDB.open('screenBD', 1);

      conexionDB.onsuccess = function (event) {
        let db = event.target.result;

        const table = db.transaction('Products').objectStore('Products');

        table.getAll().onsuccess = function (e) {
          const allProducts = e.target.result;
          const productsCategorie = allProducts.filter(x => x.category_ids.includes(parseInt(categorieId)));
          const productsByOrder = productsCategorie.sort((a, b) => a.order - b.order);
          setListProducts(productsByOrder);
          setIsEmpty(!(productsCategorie.length > 0));
          const tableCategorie = db.transaction('Categories').objectStore('Categories');

          tableCategorie.get(parseInt(categorieId)).onsuccess = function (e) {
            const categorie = e.target.result;
            setSection(categorie.name);
          };
          // debugger;

          setLoading(false);
        };
      };
      // const elemetObserver = document.querySelector('.items__wrapper');
    };

    getProducts();
  }, [categorieId]);

  // useLayoutEffect;

  useLayoutEffect(() => {
    function handleIntersection(entries) {
      const arrow = document.querySelector('.more__products');
      if (arrow !== null) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            arrow.classList.add('d-none');
          } else {
            arrow.classList.remove('d-none');
          }
        });
      }
    }

    const options = {
      threshold: 1
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(wrapperItems.current);
  }, []);

  return (
    <Layout loading={loading} section={section} isEmpty={isEmpty}>
      <div className='items__wrapper' ref={wrapperItems}>
        <div className='more__products d-none'>
          <span className='pulse'>
            <div>
              <img src={arrowMoreProducts} alt='más productos' />
            </div>
          </span>
        </div>
        {listProducts.map((product, idx) => {
          return (
            <div onClick={() => navigate(`/detail-product/${product.id}`)} className='col-4' key={product.id}>
              <Animated animationIn='zoomIn' animationOut='fadeOut' isVisible={true} animationInDuration={500}>
                <img
                  className='items__photo--product'
                  src={`data:${product.images[0].image_mime_type};base64,${product.images[0].image_1920}`}
                  alt={product.name}
                  draggable={false}
                />
                <div className='items__name'>{product.name}</div>
              </Animated>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default Products;
