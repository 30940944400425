import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
// import Footer from "./Footer";
import Loading from '../Loading';
import EmptyState from '../EmptyState';

import '../../scss/Styles.scss';
import '../../scss/Layout.scss';

const Layout = ({ children, loading, section, isEmpty = false }) => {
  const wrapperEvent = useRef(null);
  let navigate = useNavigate();

  function TimerInactive(fn, t) {
    var timerObj = setTimeout(fn, t);

    this.stop = function () {
      if (timerObj) {
        clearTimeout(timerObj);
        timerObj = null;
      }
      return this;
    };

    // start timer using current settings (if it's not already running)
    this.start = function () {
      if (!timerObj) {
        this.stop();
        timerObj = setTimeout(fn, t);
      }
      return this;
    };

    // start with new interval, stop current interval
    this.reset = function (newT) {
      t = newT;
      return this.stop().start();
    };
  }
  // function handleIntersection(entries) {
  //   const arrow = document.querySelector('.more__products');
  //   if (arrow !== null) {
  //     entries.forEach(entry => {
  //       if (entry.isIntersecting) {
  //         arrow.classList.add('d-none');
  //       } else {
  //         arrow.classList.remove('d-none');
  //       }
  //     });
  //   }
  // }
  // const options = {
  //   threshold: 1
  // };
  // const observer = new IntersectionObserver(handleIntersection, options);

  // useEffect(() => {
  //   const ele = document.querySelector('.layout__wrapper');
  //   ele.scrollTop = 100;
  //   ele.scrollLeft = 150;
  //   let pos = { top: 0, left: 0, x: 0, y: 0 };
  //   const mouseDownHandler = function (e) {
  //     ele.style.cursor = 'grabbing';
  //     ele.style.userSelect = 'none';
  //     pos = {
  //       left: ele.scrollLeft,
  //       top: ele.scrollTop,
  //       // Get the current mouse position
  //       x: e.clientX,
  //       y: e.clientY
  //     };
  //     document.addEventListener('mousemove', mouseMoveHandler);
  //     document.addEventListener('mouseup', mouseUpHandler);
  //   };
  //   const mouseMoveHandler = function (e) {
  //     // How far the mouse has been moved
  //     const dx = e.clientX - pos.x;
  //     const dy = e.clientY - pos.y;
  //     // Scroll the element
  //     ele.scrollTop = pos.top - dy;
  //     ele.scrollLeft = pos.left - dx;
  //   };
  //   const mouseUpHandler = function () {
  //     ele.style.cursor = 'grab';
  //     ele.style.removeProperty('user-select');
  //     document.removeEventListener('mousemove', mouseMoveHandler);
  //     document.removeEventListener('mouseup', mouseUpHandler);
  //   };
  //   // debugger;
  //   ele.addEventListener('mousedown', mouseDownHandler);
  // }, []);

  useEffect(() => {
    let inactiveTime = new TimerInactive(function () {
      navigate('/home');
    }, 60000);
    inactiveTime.start();
    var event = wrapperEvent.current;
    event.onclick = () => {
      inactiveTime.reset(60000);
    };
    return () => inactiveTime.stop();
  }, [navigate]);
  return (
    <>
      <main className='layout__wrapper' ref={wrapperEvent}>
        <Header section={section} />
        <section className='container'>
          {loading && <Loading />}
          {isEmpty && <EmptyState />}
          {children}
        </section>
      </main>
      <section className='loading-update d-none'>
        <Loading text='Actualizando datos, por favor espere...' />
      </section>
    </>
  );
};

export default Layout;
