import React from 'react';
import '../scss/Loanding.scss';
import logoYomo from '../images/LogoYomo.png';

const Loading = ({ text = 'Cargando...' }) => {
  return (
    <div className='loading'>
      <img className='logo-loading' src={logoYomo} alt='Logo Yomo' draggable={false} />
      <h3>{text}</h3>
    </div>
  );
};

export default Loading;
