import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Animated } from 'react-animated-css';

import goBack from '../../images/go-back-arrow.png';
import home from '../../images/home.png';

const Header = ({ section }) => {
  const [logo, setLogo] = useState('');
  let navigate = useNavigate();

  useEffect(() => {
    const conexionDB = window.indexedDB.open('screenBD', 1);

    conexionDB.onsuccess = function (event) {
      let db = event.target.result;

      db.transaction('Configuration').objectStore('Configuration').get(2).onsuccess = async function (event) {
        let { logo } = event.target.result;
        setLogo(logo);
      };
    };
  }, []);

  return (
    <Animated animationIn='slideInDown' animationOut='slideOutUp' isVisible={true} animationInDuration={500}>
      {/* <Animated animationIn='fadeIn' animationOut='fadeOut' isVisible={true} animationInDuration={1500}> */}
      <header className='layout__header'>
        <nav className='navbar'>
          <button>
            <img
              src={goBack}
              alt='Volver'
              onClick={() => {
                section === 'Categorías' ? navigate('/home') : window.history.back();
              }}
            />
          </button>
          <h2>{section}</h2>
          {section === 'Categorías' ? (
            <img className='logo__categories' src={`data:image/png;base64,${logo}`} alt='Logo Yomo' />
          ) : (
            <button
              onClick={() => {
                navigate('/categories');
              }}
            >
              <img src={home} alt='Home' />
            </button>
          )}
        </nav>
      </header>
    </Animated>
  );
};

export default Header;
