const Screen = {
  getCategories: async (code) => {
    const response = await fetch(`https://yomo.eywa.com.ar/api/screen/categories/${code}`, { cache: 'no-cache' });
    if (response.ok) {
      const result = await response.json()
      return result
    }
  },
  getProducts: async (code) => {
    const response = await fetch(`https://yomo.eywa.com.ar/api/screen/products/${code}`, { cache: 'no-cache' });
    if (response.ok) {
      const result = await response.json()
      return result
    }
  },
  getConfiguration: async (code) => {
    const response = await fetch(`https://yomo.eywa.com.ar/api/screen/config/${code}`, { cache: 'no-cache' });
    if (response.ok) {
      const result = await response.json()
      return result
    }
  },
  getLastModify: async (code) => {
    const response = await fetch(`https://yomo.eywa.com.ar/api/screen/lastmodify/${code}`, { cache: 'no-cache' });
    if (response.ok) {
      const result = await response.json()
      return result
    }
  },
  getMediaBlob: async (url) => {
    const response = await fetch(`${url}`);
    if (response.ok) {
      const result = await response.blob()
      return result
    }
  },
}

export default Screen;