import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Register from './components/Register';
import Home from './components/Home';
import Categories from './components/Categories';
import SubCategories from './components/SubCategories';
import Products from './components/Products';
import DetailProduct from './components/DetailProduct';
import ApiScreen from './services/screens';

import refresh from './images/refresh.png';

const App = () => {
  const [modeDebug, setModeDebug] = useState(false);
  const [codeApp, setCodeApp] = useState('******');
  let navigate = useNavigate();
  let location = useLocation();
  const wrapperEvent = useRef(null);

  function Timer(fn, t) {
    var timerObj = setInterval(fn, t);

    this.stop = function () {
      if (timerObj) {
        clearInterval(timerObj);
        timerObj = null;
      }
      return this;
    };

    // start timer using current settings (if it's not already running)
    this.start = function () {
      if (!timerObj) {
        this.stop();
        timerObj = setInterval(fn, t);
      }
      return this;
    };

    // start with new interval, stop current interval
    this.reset = function (newT) {
      t = newT;
      return this.stop().start();
    };
  }

  // *Funcion para la generaci�n del codigo aleatorio alfanumerico

  function codeGenerator(length) {
    // let result = '';
    // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // const charactersLength = characters.length;
    // for (var i = 0; i < length; i++) {
    //   result += characters.charAt(Math.floor(Math.random() * charactersLength));
    // }
    setCodeApp('1');
    return '1';
    // setCodeApp(result);
    // return result;
  }

  const handleDebug = () => {
    const conexionDB = window.indexedDB.open('screenBD', 1);
    conexionDB.onsuccess = event => {
      const db = event.target.result;

      const tableConfig = db.transaction('Configuration', 'readwrite').objectStore('Configuration');
      tableConfig.get(1).onsuccess = function (event) {
        const { code } = event.target.result;
        const updated = new Date().toLocaleString();
        const tableConfiguration = db.transaction('Configuration', 'readwrite').objectStore('Configuration');
        const data = { id: 1, code: code, lastUpdate: updated };
        const request = tableConfiguration.put(data);
        request.onsuccess = () => {
          navigate('/home');
        };
      };
    };
  };

  useEffect(() => {
    var timer = new Timer(function () {
      initDataBase();
    }, 60000);

    function initDataBase() {
      const conexionDB = window.indexedDB.open('screenBD', 1);

      conexionDB.onupgradeneeded = event => {
        let db = event.target.result;
        const storeConfig = db.createObjectStore('Configuration', { keyPath: 'id', autoIncrement: true });

        db.createObjectStore('Categories', { keyPath: 'id', autoIncrement: true });

        db.createObjectStore('Products', { keyPath: 'id', autoIncrement: true });

        storeConfig.transaction.oncomplete = e => {
          db.transaction('Configuration', 'readwrite')
            .objectStore('Configuration')
            .add({
              id: 1,
              // code: 1,
              code: codeGenerator(1),
              lastUpdate: new Date().toLocaleString()
            });
        };
      };

      conexionDB.onsuccess = event => {
        const db = event.target.result;

        const tableConfig = db.transaction('Configuration', 'readwrite').objectStore('Configuration');

        tableConfig.get(1).onsuccess = async function (event) {
          let { code, lastUpdate } = event.target.result;

          const responseConfiguration = await ApiScreen.getConfiguration(code);

          if (responseConfiguration.result === 'success') {
            timer !== null && timer.stop();
            const responseLastModify = await ApiScreen.getLastModify(code);
            const updated = new Date(responseLastModify.data.__last_update).toLocaleString();
            const timeUpdate = parseInt(responseConfiguration.data.reload_min) * 60 * 1000;
            // console.log('Vamos actualizar', updated);

            setModeDebug(responseConfiguration.data.debug);

            const horaActual = new Date().getHours();
            if ((horaActual >= 8) || modeDebug) {
              console.log('Actualizar datos');

              if (lastUpdate !== updated) {
                const loading = document.querySelector('.loading-update');

                loading !== null && loading.classList.remove('d-none');

                const responseCategories = await ApiScreen.getCategories(code);
                const responseProducts = await ApiScreen.getProducts(code);

                db.transaction('Categories', 'readwrite').objectStore('Categories').clear().onsuccess = e => {
                  responseCategories.data.forEach(categorie => {
                    db.transaction('Categories', 'readwrite').objectStore('Categories').add(categorie);
                  });
                };

                db.transaction('Products', 'readwrite').objectStore('Products').clear().onsuccess = e => {
                  responseProducts.data.forEach((product, idx) => {
                    db.transaction('Products', 'readwrite')
                      .objectStore('Products')
                      .add({ order: idx + 1, ...product });
                  });
                };

                const dataConfiguration = [
                  { id: 1, code: code, lastUpdate: updated },
                  {
                    id: 2,
                    // videoBlob: responseVideoBlob,
                    ...responseConfiguration.data
                  }
                ];

                dataConfiguration.forEach(config => {
                  db.transaction('Configuration', 'readwrite').objectStore('Configuration').put(config);
                });

                timer !== null && timer.reset(timeUpdate);

                loading !== null && loading.classList.add('d-none');

                // console.log('La base de datos ha sido actualizada');
                navigate('/home');
              } else {
                const path = location.pathname;
                path === '/' && navigate('/home');
                timer !== null && timer.reset(timeUpdate);
              }
            }
          }
        };
      };
    }
    initDataBase();
    return () => {
      timer.stop();
      timer = null;
    };
  }, [navigate, location, modeDebug]);

  return (
    <div className='App' ref={wrapperEvent}>
      {modeDebug && (
        <button className='mode-debug' onClick={() => handleDebug()}>
          <img src={refresh} alt='modo debug' />
        </button>
      )}
      <Routes>
        <Route path='/' element={<Register code={codeApp} />} />
        <Route path='home' element={<Home />} />
        <Route path='categories' element={<Categories />} />
        <Route path='sub-categories/:categorieId' element={<SubCategories />} />
        <Route path='products/:categorieId' element={<Products />} />
        <Route path='detail-product/:productId' element={<DetailProduct />} />
      </Routes>
    </div>
  );
};

export default App;
