import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Layout from './layout/Layout';
import { Animated } from 'react-animated-css';

import '../scss/Categories.scss';

const SubCategories = () => {
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState('');
  const [listSubCategories, setSubListCategories] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const { categorieId } = useParams();

  useEffect(() => {
    const getSubCategories = async () => {
      setLoading(true);

      const conexionDB = window.indexedDB.open('screenBD', 1);

      conexionDB.onsuccess = function (event) {
        let db = event.target.result;

        const table = db.transaction('Categories').objectStore('Categories');

        table.getAll().onsuccess = function (e) {
          const allCategories = e.target.result;
          const parentCategorie = allCategories.filter(x => x.id === parseInt(categorieId));
          setSection(parentCategorie[0].name);
          const childs = parentCategorie[0].child_id;
          let subCategories = [];
          childs.forEach(child => {
            const request = allCategories.filter(x => x.id === child)[0];
            request !== undefined && subCategories.push(request);
          });
          setSubListCategories(subCategories);
          setLoading(false);
        };
      };
    };

    getSubCategories();
  }, [categorieId]);

  useLayoutEffect(() => {
    setIsEmpty(!(listSubCategories.length > 0));
  }, [listSubCategories]);

  return (
    <Layout loading={loading} section={section} isEmpty={isEmpty}>
      <div className='items__wrapper'>
        {listSubCategories.map(categorie => {
          return (
            categorie !== undefined && (
              <Link to={`/products/${categorie.id}`} className={`col-${categorie.col}`} key={categorie.id}>
                <Animated animationIn='zoomIn' animationOut='fadeOut' isVisible={true} animationInDuration={500}>
                  <img className='items__photo' src={`data:${categorie.image_mime_type};base64,${categorie.image_1920}`} alt={categorie.name} />
                </Animated>
              </Link>
            )
          );
        })}
      </div>
    </Layout>
  );
};

export default SubCategories;
