import React, { useRef, useEffect } from 'react';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import close from '../images/close.png';

import '../scss/Modal.scss';

const Modal = props => {
  const { data, type, index } = props.options;
  const slideRef = useRef();

  useEffect(() => {
    const goto = idx => {
      data.length > 1 && slideRef.current.goTo(parseInt(idx, 10));
    };
    goto(index);
  }, [data, index]);

  const properties = {
    duration: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    indicators: false
  };

  return (
    <section className='modal__wrapper'>
      <div className='modal__content'>
        <header className='modal__header'>
          <button>
            <img src={close} alt='Boton de cerrar' onClick={props.handleClose} />
          </button>
        </header>
        <main className='modal__body'>
          {data.length > 1 ? (
            <Slide ref={slideRef} {...properties}>
              {data.map((item, idx) => {
                let src =
                  type === 'topping'
                    ? `data:${item.image_mime_type_1920};base64,${item.image_1920}`
                    : `data:${item.images[0].image_mime_type};base64,${item.images[0].image_1920}`;
                return <img className='items__photo' src={src} alt={item.name} key={`modal-${idx}`} draggable={false} />;
              })}
            </Slide>
          ) : (
            data.map((item, idx) => {
              let src =
                type === 'topping'
                  ? `data:${item.image_mime_type_1920};base64,${item.image_1920}`
                  : `data:${item.images[0].image_mime_type};base64,${item.images[0].image_1920}`;
              return <img className='items__photo' src={src} alt={item.name} key={`modal-${idx}`} draggable={false} />;
            })
          )}
        </main>
      </div>
    </section>
  );
};

export default Modal;
